import LocalStorage from "./localstorage";

export const cookieAccepted = () => {
  gtag('config', 'G-XLRB7B6X90', { 'send_page_view': true });
  gtag("consent", "update", {
    analytics_storage: "granted",
  });
  LocalStorage.setItem("ga_consent_mode", "granted");
};

export const cookieDecline = () => {
  gtag('config', 'G-XLRB7B6X90', { 'send_page_view': false });
  gtag("consent", "update", {
    analytics_storage: "denied",
  });
  deleteAllCookies();
  LocalStorage.setItem("ga_consent_mode", "denied");
};

export const getConsentModeGranted = () => {
  return LocalStorage.getItem("ga_consent_mode") === "granted";
};

function deleteAllCookies() {
  const cookies = document.cookie.split(";");

  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }
}
