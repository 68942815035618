import { Box, DialogProps, SxProps, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import Dialog from "../shared/Dialog";
import { useTranslation } from "react-i18next";
import { getColor } from "@/utils/style";
import { useRouter } from "next/router";
import { ReactNode } from "react";
import Link from "next/link";

const P = ({ children, sx }: { children: ReactNode; sx?: SxProps }) => {
  return (
    <Typography
      fontSize="1.6rem"
      lineHeight="1.8rem"
      fontWeight="400"
      color={getColor("gray600")}
      whiteSpace="pre-line"
      mb="16px"
      sx={sx}
    >
      {children}
    </Typography>
  );
};

const H = ({ children, isTable }: { children: ReactNode; isTable?: boolean }) => {
  return (
    <Typography
      fontSize={isTable ? "1.6rem" : "2rem"}
      lineHeight={isTable ? "1.8rem" : "2.2rem"}
      fontWeight="800"
      color={getColor("black")}
      whiteSpace="pre-line"
      mb="16px"
    >
      {children}
    </Typography>
  );
};

const UH = ({ children }: { children: ReactNode }) => {
  return (
    <Typography
      fontSize={"1.8rem"}
      lineHeight={"2rem"}
      fontWeight="800"
      color={getColor("black")}
      whiteSpace="pre-line"
      mb="16px"
      sx={{ textDecoration: "underline" }}
    >
      {children}
    </Typography>
  );
};

const LinkTypo = ({ children, href }: { children: ReactNode; href: string }) => {
  return (
    <Link href={href}>
      <Typography fontSize="1.6rem" lineHeight="1.8rem" fontWeight="400" color={getColor("skyblue")} component="span">
        {children}
      </Typography>
    </Link>
  );
};

export default function CookieTerms({ onClose, ...props }: DialogProps & { onClose: () => void }) {
  const { t } = useTranslation();
  const router = useRouter();

  const close = () => {
    if (router.pathname === "/cookie-terms") {
      onClose();
      router.push("/", undefined, { shallow: true });
    } else {
      onClose();
    }
  };

  return (
    <>
      <Dialog onClose={close} {...props} title={t("cookie-terms")} buttons={[]}>
        <Box
          display="flex"
          flexDirection="column"
          overflow="auto"
          sx={{ "& li::marker": { fontSize: "2rem", color: getColor("gray700") }, "& li p": { marginBottom: 0 } }}
        >
          <P>{`Updated April, 25 2023

This Cookies Policy describes the different types of cookies that may be used in connection with Hyundai Motor Company’s global website https://www.hyundai-newsletter.com including through any of our website’s contact forms, and any features, promotions, offers, or services provided by this website (collectively the “Site”). This Policy also describes how you can manage cookies.

This Policy only applies to the Site and not to any other website owned or operated by Hyundai.

It is important that you check back often for updates to the Policy as we may change it from time to time to reflect changes to our use of cookies.  Please check the date at the top of this page to see when this Policy was last revised.  Any changes to this Policy will become effective when we make the revised Policy available on our Site.
`}</P>
          <UH>What are cookies?</UH>
          <P>{`A cookie is any kind of file or device that is downloaded to a user’s system for the purpose of storing data that may be updated or retrieved by the company responsible for its installation.  The main purpose of cookies is to make it quicker for you to access the Site and to tailor the Site’s features to you, allowing information of interest or potentially of interest to be provided to you depending on your use and interests.  

We use cookies on this Site in order to understand how you use our Site and to improve your experience.  Cookies are text files which are stored on your computer. After you have finished your session and closed your browser many of the text files are deleted (“session cookies”).  So-called “persistent cookies” are also stored and these allow us to recognize you during your next visit on our Site.  You can prevent cookies being saved on your computer by changing your browser settings.  This may result in some functions used by our Site being restricted.
`}</P>
          <UH>Types of Cookies</UH>
          <P>Depending on the company managing them :</P>
          <ul>
            <li>
              <P>Our own cookies: Cookies that are sent to your system from a system or domain managed by us.</P>
            </li>
            <li>
              <P>
                Third party cookies: Cookies that are sent to your system from a system or domain that is not managed by
                us but by another company processing the data obtained through the cookies on our behalf (e.g. our
                service providers).
              </P>
            </li>
          </ul>
          <P>Depending on the period of time that they remain active :</P>
          <ul>
            <li>
              <P>
                Session cookies: Cookies designed for gathering and storing data while you are using the Site. They are
                usually used to store information that is only intended for providing the service or feature requested
                by the user on one single occasion.
              </P>
            </li>
            <li>
              <P>
                Persistent cookies: In this type of cookie, the data continues to be stored on your system and may be
                accessed and processed during a specific period of time by the manager of the cookie, which may range
                between several minutes and{" "}
              </P>
            </li>
          </ul>
          <P>Depending on their purpose:</P>
          <ul>
            <li>
              <P>
                Technical cookies: Cookies that allow the user to browse a website, platform or application, and use the
                different options or services offered, such as, for example, controlling data traffic and communication,
                identifying the session, accessing restricted areas, recalling the parts of an order, carrying out the
                process for purchase of an order, making a request to register for or participate in an event, using
                security elements during browsing, storing contents for the transmission of videos or sound or sharing
                contents through social networks.
              </P>
            </li>
            <li>
              <P>
                Analytical cookies: Cookies that enable the manager to monitor and analyze the behavior of the users of
                the websites to which they are linked. The information collected by means of this type of cookie is used
                to measure the activity of a website and analyze and improve the website’s functionality and design.
              </P>
            </li>
            <li>
              <P>
                Advertising cookies: Cookies that enable the most efficient management possible of the advertising space
                that the editor has included in a website, application or platform from which the requested service is
                provided on the basis of criteria such as the edited content or the frequency with which the
                advertisements are shown.
              </P>
            </li>
            <li>
              <P>
                Behavioral advertising cookies: Cookies that enable the most efficient management possible of the
                advertising space that the editor has included in a website, application, or platform from which the
                requested service is provided. These cookies store behavioral information about users obtained through
                the ongoing observance of their browsing habits, which allows a specific profile to be defined in order
                to show advertising on the basis of such profile.
              </P>
            </li>
          </ul>
          <UH>How We Use Cookies and Related Technologies</UH>
          <P>
            {`Our Site uses a combination of our own and third-party technical, analytical, advertising, and behavioral advertising cookies.  The data processed by technical cookies is required for the purposes mentioned above in order to protect our legitimate interests, in order to provide you with our Site, to ensure system stability and efficiency, and to implement proper safeguards of the security of our Site.  The data processed by analytical, advertising and/or behavioral advertising cookies are not strictly necessary for visiting our Site.  If required by applicable law, we will only process data by analytical, advertising and/or behavioral advertising cookies if you gave us your prior consent.

If you are a resident of the European Union please note the following information: For the use of strictly necessary cookies such as technical cookies, your consent is not required. For all other cookies that are not strictly necessary, your consent is required. If you are at least 16 years old, you can consent to the use of analytics and user cookies by pressing the switch button on the “Cookie Preferences” on this site in the footer section.
If you have decided as a general principle not to give your consent to the use of cookies that require consent, or to revoke consent that has been given, you are only provided with those functionalities you can use without your consent. 
You can customize your preferences at any time by clicking “Cookie Preferences” on this site's footer section.  
 
Our website automatically tracks information about users' site usage, and users are required to either generate or provide a user identifier on the website where the GA4 tracking code is installed.
We may combine this data with other personal information we collect about you.  This data, and the data we collect from cookies, is used to understand how our Site is used, track bugs and errors, provide and improve our Site, track sessions, prevent fraud, and protect our Site, as well as for targeted marketing and advertising, to personalize content, and for analytics purposes.  We provide additional information about tracking technologies below.  You can learn about how to register your preferences regarding certain uses of the technologies by reading the Managing Cookies and Tracking Preferences section below.

Log files.  We collect log information to deliver Site content, analyze trends, administer our Site, track users’ movement around our Site, and gather demographic information for aggregate use in improving the Site and other services.

Do Not Track.  Our Site does not support Do Not Track at this time.  Do Not Track is a privacy preference that you can set in your web browser to indicate that you do not want certain information about your webpage visits collected across websites when you have not interacted with that service on the page.  You may also specify a cookies preference that has a similar effect as a do-not-track request.
`}
          </P>
          <P>
            Third-Party Analytics. We may use third-party analytics companies, for example Google Analytics and Adobe
            Analytics Cloud⁠ (see our <LinkTypo href="/policy#privacy">Privacy Policy⁠</LinkTypo> and opt-out⁠), to
            evaluate use of our Site. We use these tools to help us understand use of, and to improve, our Site and
            other services, performance, ad campaigns, and user experiences. These entities may use cookies and other
            tracking technologies, such as web beacons or local storage objects, to perform their services.
          </P>
          <UH>List of Cookies We Use</UH>
          <Box width="100%" overflow="auto" mb="16px">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <H isTable>Cookie Type</H>
                  </TableCell>
                  <TableCell>
                    <H isTable>Cookie Name</H>
                  </TableCell>
                  <TableCell>
                    <H isTable>Usage & Description</H>
                  </TableCell>
                  <TableCell>
                    <H isTable>Expiration</H>
                  </TableCell>
                  <TableCell>
                    <H isTable>First Party / Third Party</H>
                  </TableCell>
                  <TableCell>
                    <H isTable>Cookie Provider Policy</H>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell rowSpan={3}>
                    <P>Analytics Cookies</P>
                  </TableCell>
                  <TableCell>
                    <P>_ga_B6S7HC972F</P>
                  </TableCell>
                  <TableCell>
                    <P>Used to persist session state.</P>
                  </TableCell>
                  <TableCell>
                    <P>2 years</P>
                  </TableCell>
                  <TableCell>
                    <P>Third Party (Google)</P>
                  </TableCell>
                  <TableCell>
                    <LinkTypo href="https://policies.google.com/privacy?hl=en-US">
                      https://policies.google.com/privacy?hl=en-US
                    </LinkTypo>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <P>_ga</P>
                  </TableCell>
                  <TableCell>
                    <P>
                      Registers a unique ID that is used to generate statistical data on how the visitor uses the
                      website.
                    </P>
                  </TableCell>
                  <TableCell>
                    <P>2 years</P>
                  </TableCell>
                  <TableCell>
                    <P>Third Party (Google)</P>
                  </TableCell>
                  <TableCell>
                    <LinkTypo href="https://policies.google.com/privacy?hl=en-US">
                      https://policies.google.com/privacy?hl=en-US
                    </LinkTypo>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell>
                    <P>_gid</P>
                  </TableCell>
                  <TableCell>
                    <P>Used to persist session state.</P>
                  </TableCell>
                  <TableCell>
                    <P>24 hours</P>
                  </TableCell>
                  <TableCell>
                    <P>Third Party (Google)</P>
                  </TableCell>
                  <TableCell>
                    <LinkTypo href="https://policies.google.com/privacy?hl=en-US">
                      https://policies.google.com/privacy?hl=en-US
                    </LinkTypo>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          <UH>Managing Cookies and Tracking Preferences</UH>
          <P>
            If you would like to opt-out of third party collection of information on our Site via cookies and similar
            tracking technologies, you can manage the preferences within your web browser. You will need to manage these
            preferences on all of the browsers and devices you use to access our Site. If you clear cookies on your
            browser, you will need to reset your managed preferences.
          </P>
          <P>
            You can also set your browser to refuse all cookies or to indicate when a cookie is being sent. However,
            some Site features or services may not function properly without cookies. You may disable and otherwise
            choose which cookies you want on this Site by setting your browser [
            <LinkTypo href="/cookie-preference">link to Site Cookies Preferences</LinkTypo>].
          </P>
          <UH>How to Contact Us</UH>
          <P>
            Please contact us at <LinkTypo href="mailto:vanessa@hyundai.com">vanessa@hyundai.com</LinkTypo> if you have
            any complaints or questions about this Cookies Policy or our information practices.
          </P>
        </Box>
      </Dialog>
    </>
  );
}
