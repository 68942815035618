import { getColor } from "@/utils/style";
import { Clear, ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Drawer, List, ListItem, SelectProps, Typography } from "@mui/material";
import { ReactNode, useState } from "react";

interface Option {
  label?: ReactNode;
  notDisplaying?: boolean;
  value: string | number;
}

export default function BottomSheetSelect({
  helperText,
  options,
  value,
  onChange,
  open,
  sx,
}: Omit<SelectProps, "children"> & { helperText?: string; options: Option[]; bottomSheet?: boolean }) {
  const option = options.find(({ value: option }) => option === value);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Box width="100%" display="flex" flexDirection="column">
        <Box
          onClick={() => setIsOpen(true)}
          className="MuiInput-root MuiIputBase-root"
          position="relative"
          sx={{
            borderBottomColor: `${getColor("gray500")} !important`,
            ...sx,
            cursor: "pointer",
            fontFamily: "hyundaisans",
            py: "1.2rem",
            px: "1.6rem",
            pr: "2.4rem",
          }}
        >
          {option?.label ?? option?.value}
          <Box position="absolute" top="50%" right="0.6rem" sx={{ transform: "translateY(-50%)" }}>
            {isOpen || open ? <ExpandLess /> : <ExpandMore />}
          </Box>
        </Box>
        {helperText && (
          <Typography fontSize="1.4rem" color={getColor("red")} ml="16px" mt="6px">
            {helperText}
          </Typography>
        )}
      </Box>
      <Drawer
        anchor="bottom"
        sx={{ zIndex: "1300", "& .MuiPaper-root": { overflowY: "visible" } }}
        open={isOpen || open}
        onClose={() => setIsOpen(false)}
      >
        <Box display="flex" width="100%" maxHeight="45vh" position="relative">
          <Box width="100%" maxHeight="100%" overflow="auto">
            <List
              sx={{
                bgcolor: getColor("ivory"),
                py: "16px",
                "& .MuiListItem-root": {
                  fontFamily: "hyundaisans",
                  fontSize: "16px",
                  fontWeight: 500,
                  color: getColor("black"),
                  px: "20px",
                  py: "14px",
                  cursor: "pointer",
                },
                "& .MuiListItem-root:hover": {
                  bgcolor: getColor("marineBlue"),
                  color: "white",
                },
                "& .Custom-focused": {
                  fontWeight: 800,
                  bgcolor: getColor("marineBlue"),
                  color: "white",
                  pointerEvents: "none",
                },
              }}
            >
              {options.map(({ value: optionValue, label, notDisplaying }) => (
                <ListItem
                  key={optionValue}
                  style={notDisplaying ? { display: "none" } : {}}
                  onClick={(e) => {
                    onChange?.({ ...e, target: { ...e.currentTarget, value: optionValue } } as any, "");
                    setIsOpen(false);
                  }}
                  className={optionValue === value ? "Custom-focused" : undefined}
                >
                  {label ?? optionValue}
                </ListItem>
              ))}
            </List>
          </Box>
          <Box
            width="24px"
            height="24px"
            position="absolute"
            right="20px"
            bottom="calc(100% + 20px)"
            zIndex="1600"
            sx={{ cursor: "pointer" }}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            <Clear sx={{ fill: "white", fontSize: "24px" }} />
          </Box>
        </Box>
      </Drawer>
    </>
  );
}
