import { Box, DialogProps, Switch, Typography } from "@mui/material";
import Dialog from "../shared/Dialog";
import { useTranslation } from "react-i18next";
import { getColor } from "@/utils/style";
import { useEffect, useState } from "react";
import ChevronRight from "@mui/icons-material/ChevronRight";
import { getConsentModeGranted } from "@/utils/ga";
import { cookieAccepted } from "@/utils/ga";
import { cookieDecline } from "@/utils/ga";
import { useRouter } from "next/router";

export default function CookieDialog({
  onClose,
  setIsShowTerms,
  ...props
}: DialogProps & { onClose: () => void; setIsShowTerms: (show: boolean) => void }) {
  const { t } = useTranslation();
  const [isGaActive, setIsGaActive] = useState(false);
  const router = useRouter();

  useEffect(() => {
    setIsGaActive(getConsentModeGranted());
  }, [props.open]);

  const close = () => {
    if (router.pathname === "/cookie-preference") {
      onClose();
      router.push("/", undefined, { shallow: true });
    } else {
      onClose();
    }
  };

  const onSave = () => {
    if (isGaActive) {
      cookieAccepted();
    } else {
      cookieDecline();
    }

    close();
  };

  return (
    <Dialog
      onClose={close}
      title={t("cookie-preferences")}
      buttons={[
        { content: t("cancel"), color: "white", bgcolor: getColor("gray500"), callback: close },
        { content: t("save"), color: "white", bgcolor: getColor("marineBlue"), callback: onSave },
      ]}
      {...props}
    >
      <Box display="flex" flexDirection="column" gap="20px">
        <Box display="flex" flexDirection="column">
          <Typography fontSize="1.8rem" lineHeight="2rem" fontWeight="500" color={getColor("marineBlue")}>
            {t("cookie-consent-manager")}
          </Typography>
          <Typography fontSize="1.8rem" lineHeight="2rem" fontWeight="500" color={getColor("marineBlue")}>
            {t("cookie-preferrence-description")}
          </Typography>
        </Box>
        <Box height="1px" width="100%" bgcolor={getColor("gray300")} />
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="16px"
          bgcolor="white"
          p="24px"
          borderRadius="8px"
        >
          <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
            <Typography fontSize="2rem" fontWeight="800" color={getColor("marineBlue")}>
              {t("essential-website-cookies")}
            </Typography>
            <Box py="8px" px="14px" borderRadius="4px" bgcolor={getColor("skyblue")}>
              <Typography color="white" fontSize="1.6rem" fontWeight="400">
                {t("always-active")}
              </Typography>
            </Box>
          </Box>
          <Typography fontSize="1.4rem" fontWeight="400" color={getColor("gray700")} lineHeight="1.8rem">
            {t("essential-cookie-description")}
          </Typography>
        </Box>
        <Box
          display="flex"
          flexDirection="column"
          alignItems="center"
          gap="16px"
          bgcolor="white"
          p="24px"
          borderRadius="8px"
        >
          <Box width="100%" display="flex" justifyContent="space-between" alignItems="center">
            <Typography fontSize="2rem" fontWeight="800" color={getColor("marineBlue")}>
              {t("analytics-and-user-cookies")}
            </Typography>
            <Box display="flex" alignItems="center" gap="8px">
              <Typography
                color={isGaActive ? getColor("skyblue") : getColor("gray500")}
                fontSize="1.6rem"
                fontWeight="800"
              >
                {isGaActive ? t("on") : t("off")}
              </Typography>
              <Switch checked={isGaActive} onChange={(e) => setIsGaActive(e.target.checked)} />
            </Box>
          </Box>
          <Typography fontSize="1.4rem" fontWeight="400" color={getColor("gray700")} lineHeight="1.8rem">
            {t("ga-cookie-description")}
          </Typography>
        </Box>
        <Box
          alignSelf="flex-end"
          display="flex"
          gap="8px"
          alignItems="center"
          sx={{ cursor: "pointer" }}
          onClick={() => setIsShowTerms(true)}
        >
          <Typography fontSize="1.8rem" fontWeight="400" color={getColor("black")}>
            {t("about-cookie-terms")}
          </Typography>
          <ChevronRight sx={{ fontSize: "2rem", color: getColor("black") }} />
        </Box>
      </Box>
    </Dialog>
  );
}
