import { Box, MenuItem, Typography } from "@mui/material";
import Logo from "../../assets/symbol.svg";
import Copyright from "../../assets/copyright.svg";
import { getColor } from "@/utils/style";
import { useTranslation } from "react-i18next";
import Select from "../shared/Select";
import { useState } from "react";
import CookieDialog from "../Cookie/CookieDialog";
import CookieTerms from "../Cookie/CookieTerms";

export default function Footer({
  isShowCookieTerms: initCookieTerms,
  isShowCookie: initCookie,
}: {
  isShowCookieTerms?: boolean;
  isShowCookie?: boolean;
}) {
  const { t } = useTranslation();
  const [isShowCookie, setIsShowCookie] = useState(initCookie ?? false);
  const [isShowCookieTerms, setIsShowCookieTerms] = useState(initCookieTerms ?? false);

  return (
    <Box
      width="100%"
      minHeight="70px"
      maxHeight="70px"
      height="70px"
      bgcolor={getColor("black")}
      display="flex"
      justifyContent="center"
      position="relative"
      zIndex="1000"
      sx={{ height: { xs: "auto", sm: "70px" }, minHeight: { xs: "0", sm: "70px" }, py: { xs: "8px", sm: "0" } }}
    >
      <Box
        width="1300px"
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        gap="1.2rem"

        sx={{
          width: { xs: "100%", lg: "1300px" },
          px: { xs: "16px", lg: 0 },
        }}
      >
        <Box sx={{ width: { sm: "71px", xs: "auto" }, maxWidth: { sm: "71px", xs: "60px" } }}>
          <Logo height="21px" width="auto" viewBox="0 0 71 36" />
        </Box>
        <Box
          display="flex"
          alignItems="center"
          gap="32px"
          sx={{
            flexDirection: ["column-reverse", null, "row"],
            alignItems: ["flex-end", null, "center"],
            gap: ["8px", "16px", "32px"],
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            gap="2px"
            whiteSpace="nowrap"
            sx={{ display: { xs: "none", md: "flex" } }}
          >
            <Typography color={getColor("gray500")} fontSize="1.2rem" fontWeight="400">
              {t("copyright")}
            </Typography>
            <Copyright style={{ transform: "translateY(-2px)" }} />
            <Typography color={getColor("gray500")} fontSize="1.2rem" fontWeight="400">
              {t("hyundai-motor-company-all-rights-reserved")}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" gap="8px" whiteSpace="nowrap">
            <Box
              px="1.2rem"
              py="1rem"
              border={`1px solid ${getColor("gray500")}`}
              sx={{ cursor: "pointer", px: { xs: "0.8rem", sm: "1.2rem" } }}
              onClick={() => setIsShowCookie(true)}
            >
              <Typography
                sx={{ fontSize: { sm: "1.4rem", xs: "1.2rem" } }}
                color={getColor("gray500")}
                fontWeight="500"
              >
                {t("cookie-preferences")}
              </Typography>
            </Box>
            <Select
              variant={'outlined'}
              value="0"
              sx={{
                "& .MuiOutlinedInput-notchedOutline": { borderColor: getColor("gray500") },
                "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: getColor("gray700") },
                "& .MuiSelect-select": {
                  py: "1rem",
                  px: { sm: "1.2rem", xs: "0.8rem" },
                },
                "& svg": {
                  color: getColor("gray500"),
                },
                color: getColor("gray500"),
                fontSize: { sm: "1.4rem", xs: "1.2rem" },
                fontWeight: "500",
                border: `1px solid ${getColor("gray500")}`,
              }}
              onChange={(e) => window.open(e.target.value as string, "_blank")}
              options={[
                { value: "0", label: t("shortcut-to-affiliates"), notDisplaying: true },
                {
                  value: "https://www.hyundainews.com/en-us/releases?page_title=all_releases",
                  label: "Hyundai Media Center",
                },
                { value: "https://www.hyundai.com/worldwide/en", label: "Hyundai Worldwide" },
                { value: "https://www.hyundaimotorgroup.com/main/mainRecommend", label: "Hyundai Motor Group" },
                // { value: "https://hyundai-doty.com", label: "Voice of Dealer" },
              ]}
              bottomSheet={false}
            />
          </Box>
        </Box>
      </Box>
      <CookieDialog
        setIsShowTerms={(show) => setIsShowCookieTerms(show)}
        open={isShowCookie}
        onClose={() => setIsShowCookie(false)}
      />
      <CookieTerms open={isShowCookieTerms} onClose={() => setIsShowCookieTerms(false)} />
    </Box>
  );
}
