import { getColor } from "@/utils/style";
// import { ExpandMore } from "@mui/icons-material";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { SelectProps, styled, Select as MUISelect, Typography, Box, MenuItem, useMediaQuery } from "@mui/material";
import { ReactNode } from "react";
import BottomSheetSelect from "@/components/shared/BottomSheetSelect";

const CustomSelect = styled(MUISelect)<SelectProps>(() => ({
  fontFamily: "hyundaisans",
  fontSize: "1.8rem",
  fontWeight: "500",
  borderRadius: "0",
  ".MuiInput-input": {
    "&:focus": {
      backgroundColor: "transparent",
    },
  },
  ".MuiSelect-select": {
    minHeight: "0 !important",
    height: "auto !important",
    padding: "none !important",
    lineHeight: 1,
  },
  "&.Mui-error": {
    borderBottom: `1px solid ${getColor("red")}`,
  },
  "& .MuiFormHelperText-root": {
    marginTop: "0.4rem",
    fontSize: "1.4rem",
  },
}));

interface Option {
  label?: ReactNode;
  notDisplaying?: boolean;
  value: string | number;
}

export default function Select({
  MenuProps,
  helperText,
  options,
  bottomSheet = true,
  ...props
}: Omit<SelectProps, "children"> & { helperText?: string; options: Option[]; bottomSheet?: boolean }) {
  const smMatch = useMediaQuery((theme) => (theme as any).breakpoints.down("sm"), { noSsr: true });
  if (smMatch && bottomSheet)
    return <BottomSheetSelect MenuProps={MenuProps} options={options} helperText={helperText} {...props} />;

  return (
    <Box display="flex" flexDirection="column">
      <CustomSelect
        inputProps={{ "aria-label": "Without label" }}
        variant="standard"
        disableUnderline
        MenuProps={{
          PaperProps: {
            sx: {
              "& .MuiMenuItem-root": {
                fontFamily: "hyundaisans",
                fontSize: "1.8rem",
                fontWeight: "500",
                px: "3.2rem",
                py: "1.2rem",
                minHeight: 0,
                //@ts-ignore
                ...MenuProps?.PaperProps?.sx?.["& .MuiMenuItem-root"],
              },
              boxShadow: "none",
              ...MenuProps?.PaperProps?.sx,
            },
          },
          sx: { ...MenuProps?.sx },
        }}
        IconComponent={ExpandMore}
        {...props}
        sx={{ ...props.sx, borderBottomColor: getColor("gray500") }}
      >
        {options.map(({ label, value, notDisplaying }, i) => (
          <MenuItem key={`${label}-${i}`} value={value} style={notDisplaying ? { display: "none" } : {}}>
            {label ?? value}
          </MenuItem>
        ))}
      </CustomSelect>
      {helperText && (
        <Typography fontSize="1.4rem" color={getColor("red")} ml="1.6rem" mt="0.6rem">
          {helperText}
        </Typography>
      )}
    </Box>
  );
}
